<template>
  <!-- 头条编辑项目2.0版本 -->
  <div class="paddbox toutiaoAddModel">
    <div class="toutiaoAddModelTitle">
      <el-card>
        <div class="toutiaoAddModelTitleTop flex">
          <div>
            <el-button
              type="primary"
              :disabled="openSonviewAble"
              @click="upAccountFun()"
              >{{
                openSonviewAble == false ? '请选择子账户' : '已选择完成'
              }}</el-button
            >
          </div>
          <div>
            <div class="title">
              <el-tooltip
                class="item"
                effect="dark"
                :content="sonString"
                placement="right"
              >
                <div>已选:({{ SonAccountData.length }})</div>
              </el-tooltip>
            </div>
          </div>
          <!-- <div>
            <selectSonAccount
              :data="selectSonAccountData"
            ></selectSonAccount>
          </div> -->
          <div>
            <el-button type="danger" round @click="ResetPageFun()"
              >重置</el-button
            >
          </div>
        </div></el-card
      >
    </div>
    <div class="toutiaoAddModelcontent">
      <el-card>
        <div class="toutiaoAddModel-cardbox ">
          <el-row :gutter="20">
            <!-- 项目配置 -->
            <el-col :span="12"
              ><div class="grid-content ep-bg-purple"/>
              <!-- 项目配置模块开始 -->
              <div class="toutiaoAddModel-carditem">
                <div class="defineHead">
                  <span>项目配置 </span>
                  <!-- @click="AdPlanFun" -->
                  <span
                    ><i
                      title="编辑"
                      class="cursor editIcon el-icon-edit disabled"
                    ></i
                  ></span>
                </div>
                <div class="deliveryDiv flex">
                  <div>
                    <el-form>
                      <el-form-item label="版本类型">
                        <el-radio-group
                          v-model="deliveryData.isVersion"
                          size="large"
                        >
                          <el-radio-button :label="0">旧版</el-radio-button>
                          <el-radio-button :label="1">新版</el-radio-button>
                        </el-radio-group>
                      </el-form-item>
                    </el-form>
                  </div>
                  <div>
                    <div>
                      <font color="red"
                        >* 旧版是指非自动投放项目；新版是指自动投放项目；
                      </font>
                    </div>
                    <div>
                      <font color="red">
                        注意：当前页面批量的所有项目必须是《全部是新版》或者《全部是旧版》</font
                      >
                    </div>
                    <div>
                      <font color="red">
                        roi系数如果项目没有可以不用管</font
                      >
                    </div>
                  </div>
                </div>
                <div class="defineBody">
                  <el-table
                    :data="SonAccountDataNew"
                    border
                    style="width: 100%"
                  >
                    <el-table-column
                      prop="advertiserId"
                      label="账号"
                      width="170"
                    ></el-table-column>
                    <el-table-column label="项目" prop="status" width="180">
                      <template #default="scope">
                        <el-select
                          v-model="scope.row.projectId"
                          collapse-tags
                          clearable
                          placeholder="请选择"
                          @click="getV2orangeSiteFun(scope)"
                        >
                          <el-option
                            v-for="item in scope.row.xmArr"
                            :key="item.projectId"
                            :label="item.name"
                            :value="item.projectId"
                            @click="clickPojectFun(item, scope)"
                          />
                        </el-select>
                      </template>
                    </el-table-column>
                    <el-table-column label="小程序类型" width="200px">
                      <template #default="scope">
                        <div
                          v-if="scope.row.microPromotionType == 'BYTE_APP'"
                          class="flex"
                        >
                          <p>字节小程序</p>
                          <el-select
                          style="width: 100px;"
                            v-model="scope.row.instanceId"
                            placeholder="请选择"
                            filterable
                          >
                            <el-option
                              @click="setmicroAppDtoFun(scope)"
                              v-for="item in scope.row.microAppData"
                              :key="item.instanceId"
                              :label="item.name"
                              :value="item.instanceId"
                            >
                            </el-option>
                          </el-select>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column label="小程序链接">
                      <template #default="scope">
                        <div
                          v-if="scope.row.microPromotionType == 'BYTE_APP'"
                          class="flex"
                        >
                          <el-select
                          style="width: 100px;"
                            v-model="scope.row.startParam"
                            placeholder="请选择链接"
                            filterable
                          >
                            <el-option
                              @click="setmicroAppDtoFun2(scope, item)"
                              v-for="item in scope.row.list"
                              :key="item.startParam"
                              :label="item.linkRemark"
                              :value="item.startParam"
                            >
                            </el-option>
                          </el-select>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column v-if="deliveryData.isVersion==0" label="出价" >
                      <template #default="scope">
                        <div v-if="deliveryData.isVersion==0&&((scope.row.deepBidType=='ROI_COEFFICIENT'&&scope.row.externalAction=='AD_CONVERT_TYPE_ACTIVE')==false)">
                          <el-input
              style="width:80px"
              v-model="scope.row.cpaBid"
              placeholder="请输入出价"
            ></el-input>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column v-if="deliveryData.isVersion==0" label="roi系数" >
                      <template #default="scope">
                        <div>
                          <el-input
              style="width:80px"
              v-model="scope.row.roiGoal"
              placeholder="请输入roi系数"
            ></el-input>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </div></div
            ></el-col>
            <!-- 创意配置 -->
            <el-col :span="12"
              ><div class="grid-content ep-bg-purple" />
              <!-- 创意配置模块开始 -->
              <div class="toutiaoAddModel-carditem">
                <div class="defineHead">
                  <span>创意配置 </span>
                  <span @click="LdeasSetFun"
                    ><i
                      title="编辑"
                      class="cursor editIcon el-icon-edit disabled"
                    ></i
                  ></span>
                </div>
                <div class="defineBody">
                  <div class="mandatory">
                    <div class="settingBox">
                      <!-- <span class="s_label">计划名称:</span>
                      <span class="s_text">{{ adDto.name }}</span> -->
                    </div>
                  </div>
                </div>
              </div></el-col
            >
          </el-row>
        </div>
        <!-- 按钮 -->
        <div class="toutiaoAddModelBtn flex">
          <div>
            <el-button
            type="button"
            @click="setTimeAbleFun()"
            class="el-button el-button--default el-button--small"
          ><span> 定时发布 </span>
          </el-button>
          </div>
          <div>
            <button
            @click="createPlanFun"
            type="button"
            class="el-button el-button--default el-button--small"
          >
          <span> 保存并去发布 </span>
          </button>
          </div>

        </div>
      </el-card>
    </div>
    <!-- 选择子账户弹窗 -->
    <el-dialog v-model="openSonview">
      <insertView
        @getAccountListFun="getSonAccountListFun"
        @getSelectSonAccountData="getSelectSonAccountData"
        @getCannelFun="getCannelFun"
      ></insertView>
    </el-dialog>
    <!-- 项目配置 -->
    <!-- <el-drawer size="60%" v-model="AdPlanOn" title="项目配置">
      <toutiaoprojectSetting2
        :toutiaoMedia="toutiaoMedia"
        :optionsData="optionsData"
        :getV2optimizedGoalData="getV2optimizedGoalData"
        :SonAccountDataNew="SonAccountDataNew"
        @campaignDto="campaignDto"
        @updateOptions="updateOptions"
        :getProductAvailablesData="getProductAvailablesData"
      ></toutiaoprojectSetting2>
    </el-drawer> -->

    <!-- 创意配置 -->
    <el-drawer size="70%" v-model="LdeasSetOn" title="创意配置">
      <toutiaLdeasSetSet2
        :SonAccountDataNew="SonAccountDataNew2"
        :selectGroupTitleArr="selectGroupTitleArr"
        :selectIndustryArr="selectIndustryArr"
        @flushTitleFun="flushTitleFun"
        @creativeDtoFun="creativeDtoFun"
        :SonAccountData="SonAccountData"
        :SonAccountModelData="SonAccountModelData"
        @getSonAccountFun="getSonAccountFun"
        @productOkFun="productOkFun"
        :dialogFormVisible="dialogFormVisible"
        :deliveryData="deliveryData"
      ></toutiaLdeasSetSet2>
    </el-drawer>

    <!-- 定时发布的弹窗 -->
    <el-dialog v-model="setTimeAble">
      <div>
        <div>定时发布</div>
        <el-divider></el-divider>
        <el-form label-width="140px" class="demo-ruleForm">
          <el-form-item label="备注：" >
            <el-input placeholder="请输入备注" v-model="name" clearable> </el-input>
          </el-form-item>
          <el-form-item label="设定的时间：" >
            <el-date-picker
              v-model="sTime"
              value-format="YYYY-MM-DD HH:mm:ss"
              :default-value="new Date()"
              type="datetime"
              placeholder="请选择日期时间"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <div class="addTitleBtn flex">
          <div>
            <el-button
              type="warning"
              plain
              @click="setTimeAbleFun()"
              >取消</el-button
            >
          </div>
          <div>
            <el-button type="primary" @click="scheduleCreateV2PromotionFun()"
              >确定</el-button
            >
          </div>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import selectSonAccount from '@/components/batch/selectSonAccount.vue'
import { ElMessage } from 'element-plus'
import { inject, reactive, toRefs } from 'vue'
import toutiaoAdsSetDrawer from '../toutiaoComponents/toutiaoAdsSetDrawer.vue'
import toutiaoprojectSetting2 from '../toutiaoComponents/toutiaoprojectSetting2.vue'
import toutiaoAdWebDrawer from '../toutiaoComponents/toutiaoAdWebDrawer.vue'
import toutiaLdeasSetSet2 from '../toutiaoComponents/toutiaLdeasSetSet2.vue'
import insertView from '@/components/batch/insertView.vue'
import {
  selectDirectionalNotPage,
  getV2optimizedGoal,
  selectGroupTitle,
  createV2Plan,
  selectToutiaoMedia,
  getProductAvailables,
  getProject,
  createV2Promotion,
  getV2MicroApp,
  scheduleCreateV2Promotion,
} from '@/request/new/videoBatchManagement'
export default {
  components: {
    toutiaoAdsSetDrawer,
    toutiaoprojectSetting2,
    toutiaoAdWebDrawer,
    selectSonAccount,
    toutiaLdeasSetSet2,
    insertView,
  },

  name: 'toutiaoSetModel2',
  setup() {
    const state = reactive({
      //定时的时间
      sTime:'',
      //定时备注
      name:'',
      //定时发布的弹窗
      setTimeAble:false,
      budgetOptimizeSwitch: '',
      // 打开选择子账户的弹窗
      openSonview: false,
      // 选择子账户的按钮是否可用
      openSonviewAble: false,
      // 子账户的字符串
      sonString: '',
      getProductAvailablesData: '',
      toutiaoMedia: [],
      selectGroupTitleArr: [],
      //把项目的值传递过去
      deliveryData: {
        //版本类型 0旧版 1新版
        isVersion: 0,
      },

      // 优化目标
      getV2optimizedGoalData: '',
      // 传入选择子账户组件中说明是什么类型
      selectSonAccountData: {
        type: 'toutiao',
      },
      // 选中的子账户数据
      SonAccountData: [],
      // 转化、落地页配置
      webTableData: [],
      // 请求获取定向包数据
      optionsData: [],
      // 新的子账号数据
      SonAccountDataNew: [],
      SonAccountDataNew2: [],
      campaignDtoDataSmartBidType: '',
      // 接收选择子账户组件的值
      getSelectSonAccountData: (val) => {},
      //选择版本类型
      deliveryModeFun: () => {},
      // 项目配置返回的值
      campaignDto: (val) => {
        state.campaignDtoData = val
        state.AdPlanOn = false
        // state.LdeasSetOn = true
      },
      // 请求项目信息
      getV2orangeSiteFun: (val) => {
        getProject({ accountId: val.row.advertiserId }).then((res) => {
          state.SonAccountDataNew[val.$index].xmArr = res.data
          //roi系数
          state.SonAccountDataNew[val.$index].roiGoal=1.15
          //出价
          state.SonAccountDataNew[val.$index].cpaBid=''
          
        })
      },
      //定时发布的弹窗方法
      setTimeAbleFun:()=>{
        state.setTimeAble=!state.setTimeAble
      },
      //点击下拉框项目
      clickPojectFun: (item, scope) => {
        state.SonAccountDataNew[scope.$index].microPromotionType =
          item.microPromotionType
        state.SonAccountDataNew[scope.$index].microAppData = []
        state.SonAccountDataNew[scope.$index].microAppDto = {}
        state.SonAccountDataNew[scope.$index].instanceId = ''
        state.SonAccountDataNew[scope.$index].list = []
        state.SonAccountDataNew[scope.$index].startParam = ''

        state.SonAccountDataNew[scope.$index].name = item.name
        state.SonAccountDataNew[scope.$index].deepBidType = item.deepBidType
        state.SonAccountDataNew[scope.$index].landingType = item.landingType
        state.SonAccountDataNew[scope.$index].externalAction = item.externalAction
        state.SonAccountDataNew[scope.$index].isVersion = item.isVersion

        if (item.microPromotionType == 'BYTE_APP') {
          getV2MicroApp({ accountId: item.accountId }).then((res) => {
            console.log(
              '%c [  ]-307',
              'font-size:13px; background:pink; color:#bf2c9f;'
            )
            state.SonAccountDataNew[scope.$index].microAppData = res.data
          })
        }

      },
      // 点击字节小程序
      setmicroAppDtoFun: (scope) => {
        state.SonAccountDataNew[scope.$index].microAppData.forEach((e) => {
          if (
            e.instanceId == state.SonAccountDataNew[scope.$index].instanceId
          ) {
            state.SonAccountDataNew[scope.$index].list = e.list
          }
        })

        // state.SonAccountDataNew[scope.$index].microAppData.forEach((e) => {
        //   if (
        //     e.instanceId == state.SonAccountDataNew[scope.$index].instanceId
        //   ) {
        //     state.SonAccountDataNew[scope.$index].xmArr.forEach((e2) => {
        //       console.log(
        //         '%c [ e ]-310',
        //         'font-size:13px; background:pink; color:#bf2c9f;',
        //         e
        //       )

        //       if (e2.projectId == state.SonAccountDataNew[scope.$index].xm[0]) {
        //         e2.microAppDto = e
        //         e2.microAppDto.advertiserId = scope.row.advertiserId
        //       }
        //     })

        //     console.log(
        //       '%c [  ]-313',
        //       'font-size:13px; background:pink; color:#bf2c9f;',
        //       state.SonAccountDataNew[scope.$index]
        //     )
        //   }
        // })
      },
      // 点击字节小程序
      setmicroAppDtoFun2: (scope, item) => {
        // 老代码
        // state.SonAccountDataNew[scope.$index].list.forEach((e) => {
        //   if (
        //     e.startParam == state.SonAccountDataNew[scope.$index].startParam
        //   ) {
        //     state.SonAccountDataNew[scope.$index].xmArr[0].microAppDto = e
        //     state.SonAccountDataNew[
        //       scope.$index
        //     ].xmArr[0].microAppDto.advertiserId =
        //       state.SonAccountDataNew[scope.$index].advertiserId
        //   }
        // })

        state.SonAccountDataNew[scope.$index].microAppDto.advertiserId =
          item.advertiserId
        state.SonAccountDataNew[scope.$index].microAppDto.appId = item.appId
        state.SonAccountDataNew[scope.$index].microAppDto.linkRemark =
          item.linkRemark
        state.SonAccountDataNew[scope.$index].microAppDto.startPage =
          item.startPage
        state.SonAccountDataNew[scope.$index].microAppDto.startParam =
          item.startParam
      },

      // 刷新定向包列表
      updateOptions: () => {
        selectDirectionalNotPageFun()
      },
      //  开关-----------
      LdeasSetOn: false,
      // 修改打开选择子账户的弹窗
      upAccountFun: () => {
        state.openSonview = !state.openSonview
      },
      // 接收创意配置数据
      creativeDtoFun: (val) => {
        state.creativeDto = val

        state.LdeasSetOn = false
      },
      // 选择子账户的按钮值的变化
      openSonviewAbleFun: () => {
        state.openSonviewAble = !state.openSonviewAble
      },
      // 接收组件的取消
      getCannelFun: () => {
        state.upAccountFun()
      },

      // 接收组件的确定
      getSonAccountListFun: (data) => {
        state.SonAccountData = data
        //  state.SonAccountData = val
        state.SonAccountData.forEach((item) => {
          state.SonAccountDataNew.push({
            //====有些属性在clickPojectFun()方法中产生
            advertiserId: item,
            value: '',
            //项目数据
            xmArr: [],
            xm: [],
            //新加===账户id
            accountId: item,
            //项目名称
            name: '',
            //项目id
            projectId: '',
            //判断是否有深度转化
            deepBidType: '',
            //
            landingType: '',
            // WECHAT_APP 微信小程序 BYTE_APP字节小程序
            microPromotionType: '',
          })
          state.SonAccountDataNew2.push({
            advertiserId: item,
            value: '',
            list: [],
            nativeSettings: 'douyin',
            anchorRelatedType: 'OFF',
            awemeId: '',
            anchorId: '',
            writerList: [{ val: '' }],
            selling_points: '',
          })
          state.webTableData.push({
            accountName: item,
            webData: [],
            checked: '',
            // 选中的短剧优化目标
            targeted: '',
            // 选中的短剧优化目标名称
            targetedName: '',
            targetData: [],
            // 事件管理的参数
            landPageId: '',
            asset_ids: [],
            asset_idsName: '',
            external_action: '',
            external_actionName: '',
            action_track_url: '',
            // 资产数据id
            assetData: [],
            // 资产下拉框
            assetDataList: [],
            // 直达链接
            open_url: '',
            // 事件名称的数据
            eventNameDate: [],
            // 选中的事件名称
            checkEvent: '',
            // 电商优化目标的数据
            commodityDate: [],
            // 选中的电商优化目标
            checkCommodity: '',
            // 选中的电商优化目标名称
            checkCommodityName: '',
          })
        })
        console.log(state.SonAccountDataNew, '2')
        state.openSonviewAbleFun()
        console.log('变化', state.openSonviewAble)
        data.forEach((item, index) => {
          if (data.length - 1 == index) {
            state.sonString += item
          } else {
            state.sonString += item + '，'
          }
        })
        state.upAccountFun()
      },
      // 创意配置
      LdeasSetFun: async () => {
        // state.SonAccountDataNew.forEach((element) => {})
        // 接口请求必须带上await
        if (state.SonAccountData.length < 1) {
          ElMessage({
            type: 'error',
            message: '请选择子账户',
          })
          return
        }
        // 请求标题组数据
        await selectGroupTitle().then((res) => {
          state.selectGroupTitleArr = res.data
        })
        // // 请求行业列表（创意分类）
        // await selectIndustry().then((res) => {
        //   state.selectIndustryArr = res.data
        // })
        // 必须在全部请求最后 打开抽屉
        console.log(state.campaignDtoData)
        state.LdeasSetOn = true
      },
      flushTitleFun: () => {
        selectGroupTitle().then((res) => {
          state.selectGroupTitleArr = res.data
        })
      },
      // 重置
      ResetPageFun: () => {
        location.reload()
      },

      // 接收项目配置
      AdPlanOn: false,

      // 项目配置
      AdPlanFun: () => {
        if (state.SonAccountData.length > 0) {
          getProductAvailables({ advertiserId: state.SonAccountData[0] }).then(
            (res) => {
              state.getProductAvailablesData = res.data
              state.AdPlanOn = true
            }
          )
        } else {
          ElMessage({
            type: 'error',
            message: '请选择子账户',
          })
        }
      },
      // 保存发布
      createPlanFun: () => {
        // // 使用 find 方法查找数组中是否存在 xm 属性
        // if (!state.SonAccountDataNew.find((item) => item.xm.length)) {
        //   throw ElMessage({
        //     type: 'error',
        //     message: '请选择项目ID',
        //   })
        // }

        state.SonAccountDataNew.forEach((e) => {
          if (e.projectId == '') {
            throw ElMessage({
              type: 'error',
              message: '请选择项目',
            })
          }

          if (e.microPromotionType == 'BYTE_APP' && e.instanceId == '') {
            throw ElMessage({
              type: 'error',
              message: '字节小程序不能为空',
            })
          }
          //判断旧版并且要有出价  跑iaa的roi系数可以不用出价
         
            //效验出价

            if( e.cpaBid == ''&&state.deliveryData.isVersion==0){


              console.log('e1111',e)
            console.log('e222',e.deepBidType=='ROI_COEFFICIENT'&&e.externalAction=='AD_CONVERT_TYPE_ACTIVE' )

            if((e.deepBidType=='ROI_COEFFICIENT'&&e.externalAction=='AD_CONVERT_TYPE_ACTIVE')==false){
            throw ElMessage({
              type: 'error',
              message: '手动投放出价不能为空',
            })
          }
          }
          

          
        })
        //
        console.log(
          '%c [ state.SonAccountDataNew ]-465',
          'font-size:13px; background:pink; color:#bf2c9f;',
          state.SonAccountDataNew
        )

        // // 新数组
        // const newArray = state.SonAccountDataNew.flatMap((item) =>
        //   // 进入 item.xm 的循环
        //   item.xm.flatMap((id) => {
        //     console.log(
        //       '%c [ 新数组item ]-589',
        //       'font-size:13px; background:pink; color:#bf2c9f;',
        //       item
        //     )

        //     // 在当前对象的 xmarr 数组中查找与 xm 数组中当前元素 ID 相同的对象
        //     const obj = item.xmArr.find((xm) => {
        //       xm.projectId === id
        //     })
        //     // 如果找到了对应对象，则返回一个新的对象

        //     console.log(
        //       '%c [ state.SonAccountDataNew ]-492',
        //       'font-size:13px; background:pink; color:#bf2c9f;',
        //       state.SonAccountDataNew
        //     )

        //     console.log(
        //       '%c [ obj ]-492',
        //       'font-size:13px; background:pink; color:#bf2c9f;',
        //       obj
        //     )
        //     //因为obj是从xmArr取值，用item的microAppDto
        //     return obj
        //       ? {
        //           name: obj.name,
        //           projectId: obj.projectId,
        //           deepBidType: obj.deepBidType,
        //           accountId: obj.accountId,
        //           landingType: obj.landingType,
        //           microPromotionType: obj.microPromotionType,
        //           microAppDto: item.microAppDto,
        //         }
        //       : []
        //   })
        // )

        createV2Promotion({
          promotionDto: state.creativeDto,
          // projectDaTaDtos: newArray,
          projectDaTaDtos: state.SonAccountDataNew,
        }).then((res) => {
          ElMessage({
            type: 'success',
            message: '创建成功',
          })
        })
      },
       //定时发布
       scheduleCreateV2PromotionFun:()=>{
        state.SonAccountDataNew.forEach((e) => {
          if (e.projectId == '') {
            throw ElMessage({
              type: 'error',
              message: '请选择项目',
            })
          }

          if (e.microPromotionType == 'BYTE_APP' && e.instanceId == '') {
            throw ElMessage({
              type: 'error',
              message: '字节小程序不能为空',
            })
          }
          //判断旧版并且要有出价  跑iaa的roi系数可以不用出价
         
            //效验出价

            if( e.cpaBid == ''&&state.deliveryData.isVersion==0){
            if((e.deepBidType=='ROI_COEFFICIENT'&&e.externalAction=='AD_CONVERT_TYPE_ACTIVE')==false){
            throw ElMessage({
              type: 'error',
              message: '手动投放出价不能为空',
            })
          }
          }
          

          
        })
        //效验时间
        if(state.sTime==''||state.name==''){
          return   ElMessage({
            type: 'error',
            message: '不能为空',
          })
        }
        const datas = {
          promotionDto: state.creativeDto,
          projectDaTaDtos: state.SonAccountDataNew,
          publishTime:state.sTime,
          name:state.name,
        }
        scheduleCreateV2Promotion(datas).then((res)=>{
          state.setTimeAbleFun()
          ElMessage({
            type: 'success',
            message: '定时成功',
          })
        })
      },
    })
    // 获取定向包查询2数据
    const selectDirectionalNotPageFun = () => {
      selectDirectionalNotPage().then((res) => {
        state.optionsData = res.data
      })
    }

    selectDirectionalNotPageFun()
    // 获取优化目标
    getV2optimizedGoal().then((res) => {
      state.getV2optimizedGoalData = res.data
      console.log(res)
    })
    // 获取首选媒体数据（广告计划-头条
    const selectToutiaoMediaFun = () => {
      selectToutiaoMedia().then((res) => {
        state.toutiaoMedia = res.data
      })
    }

    selectToutiaoMediaFun()
    return {
      ...toRefs(state),
      selectDirectionalNotPageFun,
    }
  },
}
</script>
<style lang="scss" scoped>
.toutiaoAddModelcontent {
  margin-top: 20px;
}
.toutiaoAddModel-carditem {
  width: 100%;
  font-size: 14px;
  border: 1px solid #e2e6f0;
  border-radius: 4px;
  margin-top: 0 !important;
  margin-right: 20px;
}
.defineHead {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f6f7fa;
  border-radius: 3px 3px 0 0;
  color: #282c33;
  padding: 12px 16px;
  box-sizing: border-box;
}
.defineBody {
  width: 100%;
  height: 400px;
  padding: 6px 12px;
  overflow: auto;
  box-sizing: border-box;
}
.mandatory {
  height: 338px;
  max-height: 338px;
}
.settingBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  .s_label {
    width: max-content;
  }
  .s_text {
    max-width: 60%;
    word-break: break-all;
  }
}
.mandatorybox {
  margin-top: 10px;
}
.tHead {
  font-size: 16px;
  font-weight: 600;
}
.toutiaoAddModelBtn {
  padding-top: 20px;
  text-align: right;
  justify-content: flex-end;
  >div{
   margin-left: 50px;
  }
}

.toutiaoAddModelTitleTop {
  > div {
    margin-right: 10px;
  }
}

.item {
  padding-top: 10px;
}

.deliveryDiv {
  margin-top: 10px;
  margin-left: 20px;
}


.addTitleBtn {
  justify-content: flex-end;
  > div {
    margin-left: 40px;
  }
}


</style>
